function draw() {
    getFps();
    playerActions(playerData);
    getAllLines();
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    drawLines3d();
    if (!running) {
        return
    }
    requestAnimationFrame(draw);
}

function getFps() {
    thisLoop = new Date();
    if (thisLoop - lastLoop > 1000) {
        fps = fpsTick
        delta = 60 / fps
        // console.log(delta)
        lastLoop = thisLoop;
        fpsTick = 0;
    }
    fpsTick += 1;
}

function getDirectionValues(direction) {
    return {
        xChange: Math.cos(direction),
        yChange: Math.sin(direction),
    }
}

function convertToRadians(direction) {
    return direction * (Math.PI / 180);
}

function touchControls(keyPress) {
    if(keyPress === 'up') playerData.speed += 5;
    if(keyPress === 'down') playerData.speed -= 5;
    if(keyPress === 'left')  playerData.direction -= turnSpeed * 5;
    if(keyPress === 'right')  playerData.direction += turnSpeed * 5;
}

function keyPlayers(p) {
    if (keyup) p.speed += (0.2 * delta);
    if (keydown) p.speed -= (0.2 * delta);
    if (keyleft === true) p.direction -= (turnSpeed * delta);
    if (keyright === true) p.direction += (turnSpeed * delta);
    // if (p.direction > 360) p.direction = p.direction % 360;
    // if (p.direction < 0) p.direction = 360 - p.direction;
    p.speed *= 0.8;
}

function playerActions() {
    keyPlayers(playerData);
    const {xChange, yChange} = getDirectionValues(playerData.direction);
    let moveX =  xChange * playerData.speed;
    let moveY =  yChange * playerData.speed;
    playerData.xPos += moveX;
    let xDir = moveX > 0 ? 1 : -1;
    if (mapTilesList[findSelectedTile((playerData.xPos + playerData.width * xDir), playerData.yPos)] === 1) playerData.xPos -= moveX;
    playerData.yPos -= moveY;
    let yDir = moveY < 0 ? 1 : -1;
    if (mapTilesList[findSelectedTile(playerData.xPos, (playerData.yPos + playerData.height * yDir))] === 1) playerData.yPos += moveY;
}

function getAllLines() {
    lineEndList = [];
    lineColourList = [];
    textureOffsetList = [];
    let xPlace;
    let startXrayPositiion = (resolution / 2) - (canvasWidth / 2);
    for (let i = 0; i < scanLines; i++) {
        xPlace = startXrayPositiion + (i * resolution );
        getSingleLineEnd(Math.atan(xPlace / DV))
    }
}

function getSingleLineEnd(lineDir) {
    let xPos = playerData.xPos;
    let yPos = playerData.yPos;
    let radianDir = playerData.direction + lineDir;
    const {xChange, yChange} = getDirectionValues(radianDir);
    let xStep = xChange < 0  ? -1 : 1;
    let yStep = yChange < 0  ? -1 : 1;
    let checkxPos = xChange > 0  ? tileSize - (xPos % tileSize) : xPos % tileSize;
    let checkyPos = yChange < 0 ? tileSize - (yPos % tileSize) : yPos % tileSize;
    let solid = 0;
    let hXpos;
    let vXpos;
    let hYpos;
    let vYpos;
    checkyPos = checkyPos * yStep;
    checkxPos = checkxPos * xStep;
    const stepAngle = Math.tan(radianDir);

    while (solid === 0) { //vertical lines
        vXpos = xPos + (checkyPos / stepAngle); 
        vYpos = yPos - checkyPos;
        solid = mapTilesList[findSelectedTile(vXpos, vYpos - yStep)];
        if (solid === 0) checkyPos += (tileSize * yStep);
    }
    const vcheckDistance = getDistance(xPos, vXpos, yPos, yPos - (checkyPos))
    solid = 0;

    while (solid === 0) { // horizontal lines
        hXpos = xPos + checkxPos; 
        hYpos = yPos - ((checkxPos) * stepAngle); 
        solid = mapTilesList[findSelectedTile(hXpos + xStep, hYpos)];
        if (solid === 0) checkxPos += (tileSize * xStep); 
    }
    const hcheckDistance = getDistance(xPos, xPos + (checkxPos), yPos, hYpos)

    if (vcheckDistance < hcheckDistance) {
        lineEndList.push(Math.cos(lineDir) * vcheckDistance);
        if (textureMode === 'none') lineColourList.push(1);
        if (textureMode != 'none') textureOffsetList.push(Math.floor(((vXpos /  tileSize) % 1) * textureSize));
    }
    if (vcheckDistance > hcheckDistance) {
        lineEndList.push(Math.cos(lineDir) * hcheckDistance);
        if(textureMode === 'none') lineColourList.push(0);
        if (textureMode != 'none') textureOffsetList.push(Math.floor(((hYpos / tileSize) % 1) * textureSize));
    }
}

function getDistance(x1,x2,y1,y2) {
    let xDif = x1 - x2;
    let yDif = y1 - y2;
    return Math.sqrt((xDif *xDif) + (yDif * yDif));
}

function drawLines3d() {
    ctx.lineWidth = resolution;
    let lineXpos = resolution / 2;
    let listLength = lineEndList.length;
    if (textureMode === 'none') {
        for (let i = 0; i < listLength; i++) {
            draw3dLine(lineEndList[i], lineXpos,i);
            lineXpos += resolution;
        }
    }
    if (textureMode != 'none')
    for (let i = 0; i < listLength; i++) {
        drawTexture3dLine(lineEndList[i], lineXpos,i);
        lineXpos += resolution;
    }
}

function draw3dLine(lineDistance, lineX, i) {
    let height = 10 * (DV / lineDistance);
    let midPoint = canvasHeight / 2;
    let lineTopHeight = midPoint - height  > 0 ? midPoint - height : 0;
    let lineBottonHeight = midPoint + height  < canvasHeight ? midPoint + height : canvasHeight;
    ctx.strokeStyle = lineColourList[i] === 0 ? `rgb(0,0,${255 - (lineDistance/ 1.2)})` : `rgb(0,${255 - (lineDistance/ 1.2)},0)`;
    ctx.beginPath();
    ctx.moveTo(lineX, lineTopHeight);
    ctx.lineTo(lineX, lineBottonHeight);
    ctx.stroke();
}

function drawTexture3dLine(lineDistance, lineX, i) {
    let textureOffset = textureOffsetList[i];
    let height = 10 * (DV / lineDistance); 
    let lineYpos = (canvasHeight / 2) - height; 
    let drawColour; 
    let newColour;
    let ti = 0; 
    let scaledTextureSize = height / resolution;
    scaledTextureSize = scaledTextureSize < 8 ? 5
                        // : scaledTextureSize < 16 ? 16
                        // : scaledTextureSize < 48 ? 32
                        : textureSize; 
    let skip = Math.floor(textureSize / scaledTextureSize); 
    let drawHeight = (((height * 2) - resolution) / textureSize) * skip; 
    let lineVMove = textureSize * skip 

    while (lineYpos + drawHeight < 0) { 
        lineYpos += drawHeight;
        textureOffset += textureSize
        ti++
    }
    ctx.beginPath();
    ctx.moveTo(lineX, lineYpos);
    while (ti < scaledTextureSize) {
        drawColour = textureColours[pixelList[textureOffset]];
        lineYpos += drawHeight;
        if( newColour != drawColour) {
            drawLineSection(lineX,lineYpos, drawColour)
        }
        textureOffset += lineVMove;
        if(lineYpos > canvasHeight) return;
        ti++
    } 
}

function drawLineSection(xPos,yPos,colour) {
    ctx.strokeStyle = colour;
    ctx.lineTo(xPos, yPos + resolution);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(xPos, yPos);
}


function findSelectedTile(x, y) {
    return (Math.floor((y / tileSize)) * mapWidth) + Math.floor(x / tileSize);
}

function drawImage(image, x, y, scale = 1, definedCtx) {
    definedCtx.setTransform(scale, 0, 0, scale, x, y); 
    definedCtx.drawImage(image, 0, 0);
}

function drawBackground() {
    const canvas = document.getElementById('background-layer');
    const ctx = canvas.getContext('2d');
    ctx.save()
    drawImage(images[0], 0, 0, 1, ctx);
    ctx.restore()
}

function completeUrl(urlList) {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/images/raycaster/' + URL));
}

function createMap() {
    mapTilesList = decodeLevel(map1);
}

function decodeLevel(levelData) {
    let mapUpload = [];
    let tileRun = 0;
    let nextTile = levelData[tileRun];
    while (tileRun < levelData.length) {
        nextTile = levelData[tileRun];
        for (let i = 0; i < levelData[tileRun + 1]; i++) {
            mapUpload.push(nextTile);
        }
    tileRun += 2;
    }
    return mapUpload;
}

function levelAssestsLoaded() {
    createMap();
    draw(); 
}

function loadImages() {
    let fullImageURL = [...completeUrl(miscImageURL)];
    fullImageURL.forEach(function(src) {
        const image = new Image();
        image.src = src;
        image.onload = function() {
            imageCount += 1;
            if(imageCount === fullImageURL.length){
                drawBackground();
                levelAssestsLoaded();
            }
        }
        images.push(image); 
    });
}

function keyHandler(event) {
    if (["ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(event.code) > -1) {
        event.preventDefault();
    }
    if (event.key === "ArrowUp") keyup = event.type === 'keydown';
    if (event.key === "ArrowDown") keydown = event.type === 'keydown';
    if (event.key === "ArrowRight") keyright = event.type === 'keydown';
    if (event.key === "ArrowLeft") keyleft = event.type === 'keydown';
}

function changeSettings(settings = {}) {
    if (settings.resolution) {
        resolution = parseInt(settings.resolution);
        scanLines = canvas.width / resolution;
    }
    if (settings.fov) {
        FOV = convertToRadians(settings.fov);
        DV = setDV();
    }
    if (settings.texture) {
        textureMode = settings.texture;
        if (textureMode != 'none') pixelList = getTextureData(textureMode);
    }
    if (settings.fullScreen) {
        let backgroundElement = document.getElementById('background-layer');
        backgroundElement.style.width = screen.width + 'px';
        backgroundElement.style.height = screen.height + 'px';
        drawBackground();
    }
    if (settings.fullScreen === false) {
        let backgroundElement = document.getElementById('background-layer');
        backgroundElement.style.width = canvasWidth + 'px';
        backgroundElement.style.height = canvasHeight + 'px';
        drawBackground();
    }
}

function setDV() {
    return (canvasWidth / 2) / Math.tan(FOV / 2);
}

function getTextureData(textureName) {
    let textureData = [...texturesList[textureName].gridList];
    textureColours = [...texturesList[textureName].coloursList];
    let decodedData = [];
    let dataRun = 0;
    let nextPixel = textureData[dataRun];
    while (dataRun < textureData.length) {
        nextPixel = textureData[dataRun];
        for (let i = 0; i < textureData[dataRun + 1]; i++) {
            decodedData.push(nextPixel);
        }
    dataRun += 2;
    }
    return decodedData;
}

function setUp(setScreenSize = 1) {
    if (running) {
        console.log('An instance of this programme is already running')
        return;
    }
    screenSize = setScreenSize;
    running = true;
    images = [];
    playerData.xPos = 500;
    playerData.yPos = 500;
    canvasWidth = 1072 * screenSize;
    canvasHeight = 608 * screenSize;
    playerData.direction = convertToRadians(180);
    document.addEventListener('keydown', keyHandler, false);
    document.addEventListener('keyup', keyHandler, false);
    canvas = document.getElementById('game-layer');
    ctx = canvas.getContext('2d');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    scanLines = canvasWidth / resolution;
    DV = setDV();
    loadImages();     
}

function cleanUp() {
    running = false;
    imageCount = 0;
    document.removeEventListener('keydown', keyHandler, false);
    document.removeEventListener('keyup', keyHandler, false);
}

let ctx;
let canvas;
let running = false;
let fps;
let fpsTick = 60;
let lastLoop = 1000;
let thisLoop = 0;
let delta = 1;
let map1 = [1,68,0,3,1,2,0,22,1,4,0,11,1,3,0,20,1,2,0,3,1,2,0,22,1,4,0,11,1,3,0,20,1,2,0,3,1,2,0,6,1,14,0,2,1,4,0,11,1,3,0,20,1,2,
    0,3,1,4,0,4,1,14,0,2,1,4,0,11,1,3,0,10,1,9,0,1,1,2,0,3,1,4,0,4,1,11,0,5,1,4,0,11,1,3,0,10,1,6,0,2,1,1,0,1,1,2,0,3,1,4,0,6,1,2,
    0,12,1,4,0,11,1,3,0,10,1,6,0,1,1,2,0,1,1,2,0,13,1,2,0,12,1,13,0,9,1,3,0,6,1,3,0,4,1,2,0,19,1,3,0,5,1,13,0,9,1,3,0,6,1,9,0,6,1,
    3,0,10,1,3,0,27,1,3,0,6,1,9,0,6,1,10,0,3,1,3,0,27,1,3,0,13,1,2,0,6,1,10,0,4,1,2,0,5,1,13,0,3,1,2,0,4,1,3,0,10,1,1,0,2,1,2,0,6,
    1,10,0,4,1,2,0,5,1,13,0,3,1,2,0,4,1,3,0,4,1,1,0,2,1,1,0,5,1,4,0,9,1,5,0,21,1,3,0,3,1,2,0,4,1,4,0,9,1,1,0,2,1,4,0,35,1,3,0,9,1,
    4,0,4,1,1,0,7,1,4,0,21,1,1,0,13,1,3,0,9,1,4,0,12,1,4,0,35,1,3,0,9,1,13,0,2,1,5,0,3,1,16,0,9,1,1,0,17,1,14,0,2,1,3,0,5,1,16,0,
    2,1,1,0,2,1,1,0,20,1,15,0,2,1,3,0,5,1,16,0,12,1,2,0,11,1,7,0,12,1,2,0,5,1,2,0,8,1,2,0,7,1,1,0,2,1,1,0,5,1,2,0,10,1,8,0,12,1,2,
    0,5,1,2,0,8,1,2,0,16,1,2,0,8,1,10,0,4,1,4,0,4,1,2,0,5,1,2,0,8,1,2,0,2,1,1,0,2,1,1,0,4,1,1,0,2,1,1,0,2,1,2,0,2,1,2,0,4,1,10,0,
    4,1,4,0,4,1,2,0,5,1,2,0,8,1,2,0,16,1,2,0,2,1,2,0,4,1,10,0,4,1,5,0,3,1,2,0,5,1,2,0,8,1,2,0,5,1,1,0,10,1,2,0,10,1,3,0,9,1,5,0,3,
    1,2,0,5,1,2,0,3,1,2,0,3,1,2,0,8,1,10,0,5,1,2,0,3,1,3,0,9,1,5,0,3,1,2,0,5,1,2,0,3,1,2,0,3,1,20,0,5,1,2,0,3,1,3,0,16,1,3,0,5,1,
    2,0,3,1,2,0,3,1,20,0,10,1,3,0,15,1,4,0,5,1,2,0,3,1,2,0,3,1,20,0,2,1,2,0,6,1,15,0,3,1,4,0,5,1,2,0,3,1,2,0,3,1,20,0,2,1,2,0,6,1,
    15,0,4,1,3,0,5,1,2,0,3,1,2,0,3,1,2,0,28,1,5,0,6,1,4,0,5,1,2,0,10,1,2,0,3,1,2,0,23,1,2,0,3,1,4,0,8,1,3,0,5,1,2,0,15,1,2,0,17,1,
    3,0,3,1,2,0,3,1,3,0,10,1,2,0,5,1,6,0,11,1,2,0,17,1,4,0,6,1,1,0,13,1,2,0,4,1,7,0,29,1,5,0,5,1,1,0,20,1,3,0,23,1,4,0,6,1,5,0,25,
    1,4,0,23,1,4,0,6,1,5,0,24,1,71];
let canvasWidth = 1072;
let canvasHeight = 608;
let screenSize = 1;
const tileSize = 16;
let mapWidth = 67;
let mapTilesList = [];
let keyup = false;
let keydown = false;
let keyright = false;
let keyleft = false;
let lineEndList = [];
let lineColourList = [];
let FOV = convertToRadians(60);
let resolution = 3;
let scanLines = canvasWidth / resolution;
let DV = setDV();
const miscImageURL = ["background.png"];
let images = [];
let imageCount = 0;
let turnSpeed = convertToRadians(3);
let textureMode = 'none';
let textureOffsetList = []; 
let textureSize = 16;
let texturesList = {
    maze: {
        gridList: [1,3,3,2,2,1,3,1,1,2,3,1,0,2,3,1,2,1,1,2,3,1,1,2,3,1,0,1,2,1,0,1,3,1,1,1,3,1,0,2,3,1,2,2,1,1,3,1,1,3,3,1,2,2,
            3,1,1,2,3,1,0,1,3,1,1,1,2,1,3,1,0,1,3,1,1,2,3,1,0,1,2,1,3,2,1,1,3,2,1,2,2,4,3,1,1,1,3,1,0,1,2,2,3,4,1,1,3,1,0,1,2,2,
            0,1,3,1,1,1,3,1,0,1,3,1,2,1,3,1,0,2,3,2,0,2,2,1,0,2,3,3,0,1,3,1,2,1,3,1,1,2,0,3,2,1,3,2,0,2,2,1,0,1,3,1,1,4,2,1,0,1,
            2,2,1,3,3,1,0,1,2,2,1,2,3,1,2,1,3,1,2,2,0,2,3,1,1,3,3,2,2,2,3,1,2,2,0,2,2,1,0,3,3,2,1,4,2,5,0,1,2,1,0,1,3,1,2,1,0,1,
            3,3,2,1,3,1,2,2,0,2,2,1,3,1,2,2,3,2,2,4,1,2,2,1,0,3,3,3,2,1,3,2,2,2,3,1,1,2,2,2,3,5,1,2,2,1,3,1,2,1,1,3,2,2,3,1,1,1,
            3,2,1,4,2,1,1,18],
        coloursList: [
            'rgb(13,84,22)', 
            'rgb(6,61,12)', 
            'rgb(25,105,24)', 
            'rgb(14,75,13)',
        ]
    },
    brick: {
        gridList:[1,5,2,1,1,6,2,1,1,3,0,5,2,1,1,1,0,5,2,1,1,1,0,7,2,1,1,1,0,5,2,1,1,1,0,7,2,1,1,1,0,5,2,1,1,1,0,2,2,6,1,
            1,2,6,1,1,2,3,1,5,2,1,1,4,2,1,1,4,2,1,1,1,0,4,2,1,1,1,0,3,2,1,1,1,0,3,2,1,1,1,0,4,2,1,1,1,0,3,2,1,1,1,0,3,2,1,1,1,0,4,2,
            1,1,1,0,3,2,1,1,1,0,3,2,1,1,1,2,5,1,1,2,4,1,1,2,3,1,3,2,1,1,4,2,1,1,4,2,1,1,2,0,3,2,1,1,1,0,3,2,1,1,1,0,3,2,1,1,1,0,4,2,
            1,1,1,0,3,2,1,1,1,0,3,2,1,1,1,0,1,2,4,1,1,2,4,1,1,2,4,1,1,2,1,1,16,2,16],
        coloursList: [
            'rgb(222, 195, 42)',
            'rgb(247, 220, 67)',
            'rgb(196, 172, 33)',
        ] 
    }
}
let textureColours = [];
let pixelList = getTextureData('brick');
let playerData = {
    xPos: 500,
    yPos: 500,
    width: 8,
    height: 8,
    direction: convertToRadians(180),
    strafe: 0,
    strafeSpeed: 0,
    speed: 0,
    drawing: {
        image: 1,
        render: {
            x: 0.5,
            y: 0.5,
        },
    }
}

export default setUp;
export {cleanUp, changeSettings, touchControls};
