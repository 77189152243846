<template>
<div>
    <ProjectPageTemplate
        projectTitle="3D RAYCASTER"
        :imageSource="require('@/assets/images/projectLinks/raycasterLink.png')"
        shortDescription="NOT SUITABLE FOR TOUCH SCREEN - Click play to start. A 3D raycaster level using tile based collisions. Use up and down arrows to move forward and backward and left and right to turn. Coded in JavaScript and adapted from a number of raycasting tutorials online made in scratch and C languages. Currently using 2D rendering context on an HTML canvas the rather than WebGL. Use settings to change the resolution, field of view(FOV) and texture the walls. Higher resolutions and using textures will slow the performance and frame rate."
        longDescription= "Calculates the distance of the player to walls by projecting a rays on tile based grid and registering when they hit a solid tile. As the ray moves across the map its x and y position are used to work the tile number on the grid it’s within. An array stores the solid status of each tile and if a tile is solid the distance from the player is then used to determine the height of wall to be drawn. This is repeated for each scanline to create the 3D view. The player's x and y position are also checked for solid tile in the same way as the rays to determine if they have collided with the walls.<br>
                        <br>The pixel width(resolution) of each line can be adjusted as can the width in degrees of the area to be displayed(Field of view or FOV). Increasing the resolution means more lines will be cast and drawn, displaying a more accurate and detailed image. Increasing the FOV displays more of the map at one time. The higher both these values are the more processing is used and can lead to drops in frame rate.<br>
                        <br>Added HTML inputs to adjust settings in regarding to the rendering and to allow for textures to the walls. The textures are 16 x 16 and stored in a grid list in the same way as the level data. When a ray collides with a wall it records how far along the tile it hits. The line is then draw down from the top of the texture checking for colour changes as it moves down."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <div id="content-container">
            <div class="content">
                <raycasterGame/>
            </div>
            <div class="screen-small">
                <UnableToDisplay
                :imageSource="require('@/assets/images/shadowExpand.svg')"
                text="SCREEN SIZE TOO SMALL"
            />
            </div>
      </div>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import raycasterGame from '../components/RaycasterGame.vue';
import UnableToDisplay from "@/components/page_elements/UnableToDisplay.vue"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'raycaster',
    components: {
        ProjectPageTemplate,
        raycasterGame,
        UnableToDisplay,
    },
    data() {
      return {
        techLinksData: [
          techLinks.javascript,
        ],
      }
    }
}
</script>

<style lang="scss" scoped>
$content-width: 1092px;

#content-container {
  .screen-small {
    display: none;
  }

  @media only screen and (max-width: ($content-width + 1)) {
    width: 100%;
    .screen-small {
      display: flex;
    }
    .content {
      display: none;
    }
  }
}
</style>