<template>
<div>
    <div id="raycaster">
        <div id="game-container" ref="screenContainer">
            <canvas id="background-layer" width="1072" height="608" ></canvas>
            <canvas id="game-layer"></canvas>
            <FullScreenButton 
                :element="$refs.screenContainer"
            />
        </div>
        <div class="play-overlay-container">
            <transition name="fade">
                <div v-if="!isHidden" @click="play" class="play-overlay">
                    <img :src="require('@/assets/images/raycasterPlayOverlay.png')">
                    <img class="arrow" :src="require('@/assets/images/arrowLarge.svg')">
                </div>
            </transition>
        </div>
    </div>

    <div class="settings-container">
        <div class="setting-box">
            <fieldset class="setting-inner-box">
                <legend>Resolution</legend>
                  <input v-model="resolution" type="range" min="1" max="10" step="1"/>
                <div class="number-display">{{resolution}} </div>
            </fieldset>
        </div>
        <div class="setting-box">
            <fieldset class="setting-inner-box">
                <legend>Field of View(Degrees)</legend>
                <input v-model="fov" type="range" min="30" max="100" step="10"/>
                <div class="number-display">{{fov}} </div>
            </fieldset>
        </div>
        <div class="setting-box">
            <fieldset class="setting-inner-box">
                <legend>Texture</legend>
                <input v-model="texture" type="radio" id="none" name="texture" value="none" checked>
                <label for="none">None</label>
                <input v-model="texture" type="radio" id="maze" name="texture" value="maze">
                <label for="maze">Maze</label>
                <input v-model="texture" type="radio" id="brick" name="texture" value="brick">
                <label for="brick">Brick</label>
            </fieldset>
        </div>
    </div>
</div>
   

</template>

<script>
import raycaster, {cleanUp, changeSettings, touchControls} from "../gameFiles/raycasterIndex";
import FullScreenButton from "@/components/page_elements/FullScreenButton";
export default {
    name: 'raycasterGame',
        components: {
        FullScreenButton,
    },
    data() {
        return {
            isHidden: false,
            resolution: 7,
            fov: 60,
            texture: 'none',
        }
    },
    watch: {
      resolution(newRes) {
        this.updateSettings({resolution: 11 - newRes});
      },
      fov(newFov) {
        this.updateSettings({fov: newFov});
      },
      texture(newTexture) {
        this.updateSettings({texture: newTexture});
      },
    },
    methods: {
        moveFwd(){
            touchControls('up');
        },
        moveBck(){
            touchControls('down');
        },
        moveLft(){
            touchControls('left');
        },
        moveRgt(){
            touchControls('right');
        },
        updateSettings(settings) {
            if (this.isHidden) changeSettings(settings);
        },
        play() {
            this.isHidden = true;
            raycaster();
        },
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>
    canvas { 
        position: absolute;
        top: 0;
        left: 0;
        @media (display-mode: fullscreen) {
            width: 100%;
            height: 100%;
        }
    }

    #raycaster {
        position: relative;
        width: 1072px;
        height: 608px;
    }
    
    #game-layer {
        width: 100%;
        height: 100%;
    }

    .game-container {
        position: relative;
    }


    .move-button {
        position: absolute;
        width:  100px;
        height: 40px;
        display: flex;

        // @media (display-mode: fullscreen) {
        //     display: flex;
        // }
    }

    .forward {
        bottom: 100px;
        left: 10px;  
    }

    .back {
        bottom: 20px;
        left: 10px;  
    }

    .left {
        bottom: 100px;
        right: 10px;  
    }

    .right {
        bottom: 20px;
        right: 10px;  
    }

    .play-overlay-container {
        position: relative;
    }

    .play-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &.hidden {
            display: none;
        }
    }

    .setting-inner-box {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 70px;
        
    }

    .setting-box {
        background:#fff;
        
        padding: 2px;
        margin: 0px 10px;
    }

    .settings-container {
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1072px;
        height: 100%;

    }

    .number-display {
        display: flex;
        font-size: 20px;
        border: 0px;
        width: 50px;
        text-align: center;
        justify-content: center;
    }

    .arrow {
        position: absolute;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .8s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>